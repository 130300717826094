// alert($);
var button = ".hamburgerButton";
var menu = ".hamburgerMenu";
var closeButton = '<span class="dashicons dashicons-menu hamburgerIcon"></span>';
var openButton = '<span class="dashicons dashicons-no-alt hamburgerIcon"></span>';
$(document).on('click', button, function(){
  if($(button).hasClass("hamburgerOpen")){
    $(this).removeClass("hamburgerOpen").addClass("hamburgerClosed");
    $(menu).removeClass("hamburgerOpen").addClass("hamburgerClosed");
    $("header").removeClass("hamburgerOpen").addClass("hamburgerClosed");
    closeMenu();
  }
  else if($(button).hasClass("hamburgerClosed")){
    $(this).removeClass("hamburgerClosed").addClass("hamburgerOpen");
    $(menu).removeClass("hamburgerClosed").addClass("hamburgerOpen");
    $("header").removeClass("hamburgerClosed").addClass("hamburgerOpen");
    openMenu();
  }
});
function openMenu(){
  // console.log("open");
  var menu = ".hamburgerMenu";
  $(menu).animate({"height": "100%"}, 500);
  $(button).html(openButton);
}
function closeMenu(){
  // console.log("closed");
  var menu = ".hamburgerMenu";
  $(menu).animate({"height": "0%"}, 500);
  $(button).html(closeButton);
}

$(document).ready(function () {
  $(".has-submenu").on('click', function(){
      if($(this).parents(".div-has-submenu").hasClass("has-menu-highlight")){
          $(this).parents(".div-has-submenu").removeClass("has-menu-highlight");
          $(this).find(".icon-down").show();
          $(this).find(".icon-dropmenu-up").removeClass("display-inline-block");
      }
      else{
          $(this).parents(".div-has-submenu").addClass("has-menu-highlight");            
          $(this).find(".icon-down").hide();
          $(this).find(".icon-dropmenu-up").addClass("display-inline-block");
      }
  });
});