
if (jQuery(window).width() < 1279) {
  jQuery( ".large-cell" ).remove();
}
$('.info').popover();


$(':not(#anything)').on('click', function (e) {
    $('.info').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons and other elements within a button that triggers a popup
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
            return;
        }
    });
});