var button = ".faq-question";
var menu = ".faq-question-answer";
var icon = ".faq-question-icon";
$(document).on('click', button, function(){
  if($(this).hasClass("faqOpen")){
    $(this).removeClass("faqOpen").addClass("faqClosed");
    $(this).siblings("div").removeClass("faqOpen").addClass("faqClosed");
    $(this).find(icon).removeClass("faqOpen").addClass("faqClosed");
  }
  else if($(this).hasClass("faqClosed")){
    $(this).removeClass("faqClosed").addClass("faqOpen");
    $(this).siblings("div").removeClass("faqClosed").addClass("faqOpen");
    $(this).find(icon).removeClass("faqClosed").addClass("faqOpen");
  }
});
