jQuery("body").scroll(function () {

  const bottom_height = jQuery(".footer-container").height();
  const scroll = jQuery("body").scrollTop();
  const minimum_scroll = (jQuery(document).height() - jQuery(window).height()) - bottom_height;
  const margin = 50 + (((scroll - minimum_scroll) / jQuery(window).width()) * 100) / 5;

  if(scroll >= minimum_scroll){
    jQuery(".boat-parallax").css({"left": margin+"%"});
  }
});